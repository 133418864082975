import "./styles/quasar.scss";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import Notify from 'quasar/src/plugins/Notify.js';;

Notify.setDefaults({
  position: "bottom-left",
  timeout: 2000,
});

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: { Notify },
  iconSet: iconSet,
};
