type RequestMethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type RequestType = "json" | "text";

type RequestHeadersType = { [key: string]: string };

export type HttpRequestType<BodyType> = {
  url: string;
  method?: RequestMethodType;
  type?: RequestType;
  auth?: boolean;
  headers?: RequestHeadersType;
  body?: BodyType;
  query?: string;
};

export function createHttpRequest<BodyType>(config: HttpRequestType<BodyType>): HttpRequestType<BodyType> {
  return Object.assign(
    {
      method: "GET",
      type: "json",
      auth: false,
      headers: {},
      body: null,
      query: "",
    },
    config
  );
}
