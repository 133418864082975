import { InternalServerError } from "@/core/http/errors/internal-server.error";
import { NotFoundError } from "@/core/http/errors/not-found.error";
import { Notify } from "quasar";
import { UnauthorizedError } from "@/core/http/errors/unauthorized.error";
import { clearSession } from "@/core/http/get-token";
import { ForbiddenError } from "@/core/http/errors/forbidden.error";
import { ComponentPublicInstance } from "vue";

const loginURL = process.env.VUE_APP_LOGIN_REDIRECT;

export function errorHandler(e: unknown, instance: ComponentPublicInstance | null) {
  Notify.create({
    closeBtn: "cerrar",
    timeout: 0,
    type: "negative",
    message: `Ha ocurrido un error: ${e["message"]}`,
  });

  if (e instanceof InternalServerError) {
    instance.$router.push({ name: "500" });
  } else if (e instanceof NotFoundError) {
    instance.$router.push({ name: "404" });
  } else if (e instanceof UnauthorizedError || e instanceof ForbiddenError) {
    clearSession();
    window.location.href = loginURL;
  }
}
