import { BadRequestError } from "./errors/bad-request.error";
import { UnauthorizedError } from "./errors/unauthorized.error";
import { ForbiddenError } from "./errors/forbidden.error";
import { NotFoundError } from "./errors/not-found.error";
import { InternalServerError } from "./errors/internal-server.error";
import { createHttpRequest, HttpRequestType } from "@/core/http/create-http-request";
import { getToken } from "@/core/http/get-token";

const baseUrl = process.env.VUE_APP_API_URL;

export async function makeHttpRequest<RequestBody, ResponseData>(config: HttpRequestType<RequestBody>) {
  const httpRequest = createHttpRequest<RequestBody>(config);
  let token = null;
  if (config.auth) {
    token = await getToken();
  }
  const fetchConfig: RequestInit = {
    method: httpRequest.method,
    headers: httpRequest.headers,
  };

  if (httpRequest.type === "json") {
    fetchConfig.headers = { ...fetchConfig.headers, "Content-Type": "application/json", token: token };
  }

  if (httpRequest.body) {
    fetchConfig.body = JSON.stringify(httpRequest.body) as unknown as ReadableStream;
  }

  let url = baseUrl + httpRequest.url;
  if (httpRequest.query) {
    url += "?" + httpRequest.query;
  }

  const response = await fetch(url, fetchConfig);

  if (response.ok) {
    try {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const { data } = await response.json();
        return data as ResponseData;
      }
      const responseText = response.text();
      return responseText as unknown as ResponseData;
    } catch (e) {
      return response as unknown as ResponseData;
    }
  } else {
    switch (response.status) {
      case 400:
        throw new BadRequestError(await this.getErrorMessage(response));
      case 401:
        throw new UnauthorizedError(await getErrorMessage(response));
      case 403:
        throw new ForbiddenError(await getErrorMessage(response));
      case 404:
        throw new NotFoundError(await getErrorMessage(response));
      case 500: {
        throw new InternalServerError(await getErrorMessage(response));
      }
    }
  }
}

async function getErrorMessage(response) {
  const responseJson = await response.json();
  return responseJson.message;
}
