import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Quasar } from "quasar";
import quasarUserOptions from "./quasar-user-options";
import "quasar/dist/quasar.sass";
import { createI18n } from "vue-i18n";
import { es } from "@/translations/es";
import "./styles/main.scss";
import { createPinia } from "pinia";
import "reflect-metadata";
import { errorHandler } from "@/core/http/error.handler";

const i18n = createI18n({
  locale: "es",
  fallbackLocale: "es",
  messages: { es }, // set locale messages
});

const app = createApp(App);
app.config.errorHandler = (err, instance) => errorHandler(err, instance);
app.use(i18n).use(Quasar, quasarUserOptions).use(createPinia()).use(router).mount("#app");
// catchUnhandledRejection(instance.$router);
