import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";

export const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Contract", params: { year: "2022", propertyId: "5", id: "1" } },
  },
  {
    path: "/:propertyId",
    name: "View or Create Contract",
    component: () => import("../views/Property.vue"),
    props: true,
  },
  {
    path: "/:propertyId/contract/:id/:year",
    name: "Contract",
    redirect: { name: "Contract mode" },
    component: () => import("../views/Contract.vue"),
    props: true,
    children: [
      {
        path: "/:propertyId/contract/:id/:year/mode",
        name: "Contract mode",
        component: () => import("../views/form/mode/Mode.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/cancellation-policy",
        name: "Contract cancellation policy",
        component: () => import("../views/form/cancellations/CancellationPolicy.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/checkin-time",
        name: "Contract check in time",
        component: () => import("../views/form/check-in-time/CheckInTime.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/collaboration-type",
        name: "Contract collaboration type",
        component: () => import("../views/form/collaboration-type/CollaborationType.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/deposits",
        name: "Contract deposits",
        component: () => import("../views/form/deposits/Deposits.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/notices-restrictions",
        name: "Contract Notices and restrictions",
        component: () => import("../views/form/notices-and-restrictions/NoticesAndRestrictions.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/pets-policy",
        name: "Contract pets policy",

        component: () => import("../views/form/pets-policy/PetsPolicy.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/rates",
        name: "Contract rates",
        component: () => import("../views/form/rates/Rates.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/payment-methods",
        name: "Contract payment methods",
        component: () => import("../views/form/payment-methods/PaymentMethods.vue"),
      },
      {
        path: "/:propertyId/contract/:id/:year/especial-clauses",
        name: "Contract especial clauses",
        component: () => import("../views/form/special-clauses/SpecialClauses.vue"),
      },
    ],
  },
  {
    path: "/:propertyId/contract/:id/history",
    name: "History",
    component: () => import("../views/history/History.vue"),
    props: true,
  },
  {
    path: "/error/500",
    name: "500",
    component: () => import("@/views/public/500.vue"),
  },
  {
    path: "/error/404",
    name: "404",
    component: () => import("@/views/public/404.vue"),
  },
  {
    path: "/error/403",
    name: "403",
    component: () => import("@/views/public/403.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/public/404.vue"),
  },
] as RouteRecordRaw[];

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
