import { makeHttpRequest } from "@/core/http/make-http-request";

const cookieUrl = "auth-cookie-name";
const localStorageCookieName = "cookie_name";

export async function getToken() {
  const name = await getCookieName();
  localStorage.setItem(localStorageCookieName, name);
  const token = getCookie(name);
  if (!token) {
    // TODO uncomment when login in backend is completlty done
    // throw new UnauthorizedError("Empty token");
  }
  return token;
}

const getCookieName = async () => {
  const cacheCookieName = localStorage.getItem(localStorageCookieName);
  if (cacheCookieName) return cacheCookieName;

  return makeHttpRequest<null, null>({
    url: `/${cookieUrl}`,
    auth: false,
  }).then((cookie) => cookie);
};

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function clearSession() {
  localStorage.clear();
}
