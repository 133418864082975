export const es = {
  title: "Rustical Travel",
  list: {
    title: "Listado de propiedades",
  },
  navbar: {
    title: "Gestor de contratos de la propiedad:",
    contractLanguage: "Idioma del contrato",
  },
  loadingText: "Por favor, espere...",
  menu: {
    mode: {
      title: "Modalidad",
    },
    cancellation: {
      title: "Cancelaciones",
    },
    checkin: {
      title: "Hora de entrada y salida",
    },
    collaboration: {
      title: "Tipo de colaboración",
    },
    deposits: {
      title: "Fianzas",
    },
    notices: {
      title: "Avisos y restricciones",
    },
    payments: {
      title: "Formas de pago",
    },
    pets: {
      title: "Política de mascotas",
    },
    rates: {
      title: "Tarifas",
    },
    clauses: {
      title: "Clausulas especiales",
    },
  },
  footer: {
    history: "Histórico",
    discard: "Descartar",
    save: "Guardar",
    send: "Enviar",
  },
  history: {
    title: "Histórico",
    downloadTextAvailable: "Descargar PDF",
    downloadTextUnavailable: "PDF no disponible.",
  },
  contract: {
    title: "Contrato",
  },
  form: {
    mode: {
      title: "Modalidad",
      links: "Links importación",
      actionAddLink: "Añadir link de importación",
    },
    cancellation: {
      ownerTitle: "7.1 Política de cancelaciones con propietario",
      note: "NOTA INTERNA: Las condiciones pactadas con propietario deben ser mínimo una semana de ventaja respecto a las publicadas online",
      clientTitle: "7.2 Política de cancelaciones con cliente",
    },
    checkin: {
      title: "Hora de entrada y salida",
    },
    collaboration: {
      title: "2. Tipo de colaboración",
    },
    deposits: {
      title: "5. Fianzas",
      deposit: "Depósito de seguridad",
      administeredBy: {
        rustical: "Administrado por Rustical Travel",
        owner: "Administrado por el Propietario",
      },
      depositCash: {
        arrival: "Efectivo a la llegada",
        card: "Tarjeta",
        bank: "Transferencia bancaria antes de llegar",
        other: "Otros",
      },
    },
    notices: {
      title: "Avisos y restricciones",
      night: "3.1 Restricciones por noche",
      people: "3.2 Restricciones por personas",
      pool: "3.3 Restricciones de la piscina",
      events: "3.4 Restricciones de eventos",
      procedure: "Notas internas agente de reservas",
      phrases: "Frases tipo",
      phrasesType: {
        bookingnotes: "Notas de reserva",
        arrivalinfo: "Información de llegada",
        changeoverdays: "Días de cambio",
        nightrestrictionsdates: "Restricciones de noches por fechas",
        nightrestrictions: "Restricciones de noches",
        notasfijasvisibles: "Notas fijas visibles",
        accomodationoptions: "Opciones de acomodación",
        "pets-special-conditions": "Política especial de mascotas",
        "people-restrictions": "Restricciones de personas",
        "pool-restrictions": "Restricciones de piscina",
        "optional-service-costs": "Costes opcionales de servicios",
        "optional-equipment-costs": "Costes opcionales de equipamiento",
        "mandatory-service-costs": "Costes obligatorios de servicios",
        "mandatory-equipment-costs": "Costes obligatorios de equipamiento",
        "pets-additional-costs": "Costes adicionales de mascotas",
        "special-clauses": "Clausulas especiales",
        "special-cancellations-with-owner": "Cancelaciones especiales con propietario",
        "check-out-additional-information": "Información adicional de check-out",
        liaison_instructions_destination: "Instrucciones de llegada",
        liaison_instructions_what_to_do: "Qué hacer en la zona",
      },
    },
    payments: {
      title: "6.1. Formas de pago",
      title_2: "6.2. Datos de pago al propietario de esta propiedad",
      paymentDays: "Cuando se paga",
      advance: "Anticipo",
      advanceAmount: "Cuanto anticipo",
      paymentAccountNumber: "Cuenta seleccionada",
      paymentAccountOwner: "Titular seleccionado",
      verifiedAccount: "Cuenta verificada",
      dynamicNotes: "Notas dinámicas",
    },
    pets: {
      title: "9. Política de mascotas",
    },
    rates: {
      title: "Tarifas",
      title_1: "4.1. Importes al percibir el propietario",
      title_1_1: "4.1.1. Estancias 7 noches o mas",
      title_1_2: "4.1.2. Opción de acomodación",
      title_1_3: "4.1.3. Estancias 4, 5 y 6 noches",
      title_4_2: "4.2. Reducción estancias largas",
      title_3: "4.3. Costes adicionales obligatorios para el cliente",
      title_3_subtitle: "No incluyen por check-in ni mascotas",
      prices_status: "Status de los precios para el año",
      add_notes_price_reduction: "Añadir notas sobre la reducción de precio",
      with_discount: "CON reducción",
      without_discount: "SIN reducción",
      night_or_more_apply: "noches o más aplicar",
      percent_over_cost: "% Precio de coste",
      with_additional_cost: "CON coste adicional",
      with_additional_cost_optional: "CON coste adicional opcionales",
      with_additional_cost_required: "CON coste adicional obligatorio",
      without_additional_cost: "SIN coste adicional",
      add_notes_about_discount: "Añadir notas sobre la reducción de precio",
      notes_about_discount: "Notas sobre descuento",
      no_accommodation_options: "Esta propiedad no tiene Opciones de Acomodación.",
    },
    clauses: {
      title: "10. Clausulas especiales",
    },
    checkInTime: {
      title: "8. Hora de entrada y salida (check-in/check-out)",
    },
  },

  textEditor: {
    characters: "caracteres",
    minValidationText: "Necesitas escribir un mínimo de",
  },

  generalText: {
    price_for: "Precio para",
    people: "personas",
    view_mode: "Modo visualización",
    edit_mode: "Modo edición",
    per_night: "por noche",
    cost_price: "Precio coste",
    sell_price: "Precio venta",
    add_date_range: "Añadir rango de fechas",
    add_cost: "Añadir coste",
    add_additional_cost: "Añadir coste adicional",
    add_dates_range: "Añadir rango de fechas",
    services: "Servicios",
    facilities: "Equipamiento",
    penalization: "Penalización",
    a_in_dates: "a",
    margin: "Magen",
    to: "Para",
    from: "Desde",
    published: "Publicado",
    unPublished: "No publicado",
    uncommunicatedLenguaje: "Idioma sin informar",
  },
  modalContracts: {
    titleCreate: "Ver contratos",
    titleNoContractAvailable: "Sin contratos disponibles. ¿Crear contrato?",
    btnViewContract: "Ver contrato",
    btnCreateContract: "Crear contrato",
    btnThisYear: "AÑO EN CURSO",
    btnPastYear: "AÑO ANTERIOR",
  },
  modalSendContract: {
    title: "Send contract",
    subtitle: "What kink of contract would you like to send?",
    sendButton: "Enviar",
    resendButton: "Reenvío",
    modificationButton: "Modificación",
    renovationButton: "Renovación",
    initialButton: "Inicial",
    draftButton: "Borrador",
    pdfButton: "Descargar pdf",
    sendEmailButton: "Enviar email",
    sendEmailTitle: "Revisa el email",
    sendEmailText: "Asegúrate de que los datos son correctos",
  },
};
